import { useEffect } from 'react';
import './App.css';
// import "antd/dist/antd.min.css";
import 'antd/dist/reset.css';

import Views from './Views';
import { getPrj } from "./constants/methods";


const App = () => {
  //Este usseEffect actualiza dinamicamente el favicon y el title de la pestaña del panel en funcion del nombre del host cuando buscamos el companyPath en la url 
  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = `/assets/img/${getPrj()}/icono.png`;

    document.title = `${process.env[`REACT_APP_${getPrj().toUpperCase()}`] || process.env.REACT_APP_TGD} - Panel de mando`;

  }, [window.location.host]);

  
  return (
    <Views />
  )
}

export default App;